<script setup>
  import { Filter } from 'lucide-vue-next'
  import { router } from '@inertiajs/vue3'

  defineProps({
    user: Object,
  })

  const logout = () => {
    router.delete('/logout')
  }
</script>
<template>
  <header class="py-4 bg-white border-b-2">
    <div class="max-w-4xl mx-auto flex justify-between items-center w-full">
      <Filter class="w-12 h-12" />

      <nav class="flex items-center">
        <img :src="user.gravatar" :alt="user.name" class="w-12 h-12 rounded-full" />
      </nav>
    </div>
  </header>
  <main class="max-w-4xl mx-auto w-full mt-16 mb-2 bg-white px-16 py-8 rounded border ">
    <slot />
  </main>
  <footer class="text-slate-700 py-4 flex items-center justify-center space-x-4 max-w-4xl mx-auto w-full">
    <a href="https://go.limiter.dev/docs">API Docs</a>
    <a href="mailto:bilal@limiter.dev">Support</a>
    <button @click="logout">Sign Out</button>
  </footer>
</template>